import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Table } from 'antd';
import "antd/dist/antd.css";

import StyledImage from "../components/common/styled-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem } from '../components/common/lists'
import useHeadings from '../lib/useHeadings'
import { online, premeeting } from '../lib/tables'
import { StyledH3 } from '../styles/custom'



const OnlinePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const tableOfContents = useHeadings();

  return (
    <Layout location={location} title={siteTitle} toc={tableOfContents}>
      <Seo title="スマホ診療について" />
      <h2 id="スマホ診療について"> スマホ診療について </h2>

        <p>
        スマホ診療とは、スマートホンを介して遠方にいる医師の診察を受ける診療方法です。
        いくつか条件がありますが、基本的には<strong> スマートホンとクレジットカード</strong>があれば利用可能です。
        実施時間は、<strong>毎週金曜の14:00から17:30まで</strong>です。
        </p>

        <p>
        スマホ診療には、通常の対面診療と比べて以下のような利点があります。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/feature1.png"
            quality={95}
            alt="通院の時間と交通費がかからない"
          />
        </StyledImage>

        <p>
        通院に要する時間と交通費を節約できます。
        当院の場合、片道の交通費におおむね1000円以上かかる場合にはスマホ診療のほうが経済的です。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/feature2.png"
            quality={95}
            alt="待合室で待つ必要がない"
          />
        </StyledImage>

        <p>
        自宅でくつろぎながら診察を待つことができるので、待ち時間が実質的にゼロになります。
        また、待合室での感染のリスクを心配する必要もありません。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/feature3.png"
            quality={95}
            alt="場所を問わず診察が受けられる"
          />
        </StyledImage>

        <p>
        必要であれば、外出先や勤務先からでも診察を受けることが可能です。
        ただし、静かな環境で受診することが望ましいので、原則として自宅で診察を受けてください。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/feature4.png"
            quality={95}
            alt="薬や処方せんが家に届く"
          />
        </StyledImage>

        <p>
        後日、自宅に処方せんが届きます。当院は院外処方の医療機関ですので、届いた処方せんを調剤薬局に提出する必要があります。
        なお、2021年4月より近隣の薬局と連携して、お薬が直接ご自宅に届くサービス(<a href="https://curon.co/pharmacy/medguide"> オンライン服薬指導</a>)も利用可能になりました。
        </p>

        <h2 id="利用方法">利用方法</h2>

        <p>
          当院のスマホ診療サービスは、<a href="https://curon.co/"> クロン curon </a>を採用しています。
          診療の予約・診察・処方・決済までをインターネット上で行うことができます。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/step0.png"
            quality={95}
            alt="ステップ0"
          />
        </StyledImage>


        <p>
        少なくとも初回は対面での診療を必須としており、オンラインでの診療は少なくとも2回目以降となります。
        ただし、受診前相談については対面診療は不要です。
        </p>

        <p>
        予約はあらかじめ設定された枠のなかから、患者さん自身が診療時間を選びます。
        予約可能な時間帯は、毎週金曜の午後14時から17時30分までです。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/step2.png"
            quality={95}
            alt="ステップ2"
          />
        </StyledImage>

        <p>
        予約時間が近づけば、携帯電話を準備してビデオルームに入室してください。
        ビデオを介した診療は最初は違和感があるかも知れませんが、対人緊張の強い方はむしろ対面でないほうが落ち着けるかも知れません。
        </p>

        <StyledImage>
          <StaticImage
            className=""
            width={200}
            formats={["auto", "webp", "avif"]}
            src="../images/curon/step3.png"
            quality={95}
            alt="ステップ3"
          />
        </StyledImage>

        <p>
          処方せんは、後日自宅に郵送します。配送には日本郵便の<a href="https://www.post.japanpost.jp/service/clickpost/"> クリックポスト</a>を利用しています。
          もし、直接お薬をご自宅に郵送してもらいたい場合には診察のさいに医師にご相談ください。
        </p>


      <StyledH3 id="保険診療で利用する場合">保険診療で利用する場合</StyledH3>

        <p>
        すでに当院に通院中の方は、原則として保険診療での当サービスを利用できます。利用には、以下のようなケースを想定しています。
        </p>

        <ListItem 
          items={[
            "強迫性障害やパニック障害などの症状があって、外出が難しい", 
            "外出や待合室での感染のリスクに不安がある", 
            "住居が遠方にあるので、通院の時間的経済的な負担を減らしたい", 
            "高齢や身体的な障害があって、通院の身体的な負担が大きい", 
            "幼い子どもや介護を要する家族がいて、クリニックで長時間待つことが難しい",
            "仕事や育児家事に多忙で、通常の診療時間内に受診することが難しい"
            ]}
          >
        </ListItem>

        <p>
        料金は、通常の電話再診による保険診療に加えて、自費診療として遠隔診療利用料や配送手数料がかかります。
        例えば、保険診療の自己負担割合が3割で、処方せんが発行された場合は、以下のような料金となります。
        ただし処方せん料は、処方される薬剤の種類や内容に応じて、若干の変動があります。
        </p>


        <Table 
          dataSource={online.dataSource} 
          columns={online.columns} 
          size="small" 
          style={{marginBottom: "1rem"}}
          pagination={false} bordered 
        />

          <pre>
          (73+約68+1)点 X 10 X 0.3 + 2000円 + 550円
          =  約3050円
          </pre>

          なお保険診療でのスマホ診療を利用する場合には、以下の注意事項があります。

          <ListItem 
            items={[
              "少なくとも初回は対面で診療する必要があります。",
              "医師の判断により、対面診療への移行をお願いすることがあります。",
              "1回の診療時間はおおむね5分程度を想定しています。 それ以上の時間がかかる場合は、クリニックへの受診をお願いいたします。",
              "子ども医療などの一部の公費負担医療は利用できない場合があります。あらかじめ当院までメールでご相談ください。",
              "生活保護による医療扶助も利用できません。サービスの利用にはクレジットカードが必須となっているからです。"
              ]}
            >
          </ListItem>

          <StyledH3 id={"受診前に相談したい場合"}> 受診前に相談したい場合 </StyledH3>

            <p>
            当院をまだ一度も受診していない方は、受診前相談としてのサービスを利用できます。
            利用するケースとしては、以下のような場面を想定しています。
            </p>

            <ListItem 
              items={[
                "本人やご家族について受診が必要かどうかを相談したい。",
                "受診前に当院が自分にとって適切な医療機関かどうかを確認したい。",
                "セカンドオピニオンを聞きたい",
                "保険証を使いたくない"
                ]}
              >
            </ListItem>

            <p>
            受診前相談は全て自費診療であり、一律に下記の料金となります。
            </p>

            <Table 
              dataSource={premeeting.dataSource} 
              columns={premeeting.columns} 
              size="small" 
              style={{marginBottom: "1rem"}}
              pagination={false} 
              bordered />

            <p>
            受診前相談の利用に際しては、以下の注意事項があります。
            </p>

            <ListItem 
              items={[
                '一度も受診したことがない方が対象なので、利用を希望する場合は<a id="mailto" href="mailto:sendai.mental.clinic@gmail.com">メール </a>にてご相談ください。',
                "利用手順を説明する必要があり、電話では受け付けておりません。",
                "1回の相談時間は、おおむね30分程度です。",
                "通常の診療ではないので、処方せんや診断書の発行は<strong>できません</strong>。",
                "受診前相談の利用は、1回のみです。",
                "受診前相談のあとで当院にて対面での診療を希望する場合は、保険診療上は初診となります。"
                ]}
              >
            </ListItem>

            <h2 id="アプリを準備する">アプリを準備する</h2>

              <p>
              利用を開始するには、まずはクロンへの登録が必要です。
              ご自身のスマートホンで <a href="https://curon.co/home?ref=header">クロンのサイト</a> に行き、<strong>オンライン診療アプリcuron（クロン）</strong>をインストールしてください。
              </p>

              <p>
              当院の<strong>医療機関コード</strong>は、4c3e です。
              登録が完了したら、当院からの承認をお待ちください。
              </p>

              <p>
              下記ページを利用すればアプリをダウンロードせずに ダイレクトに当院の申込ページにアクセスできます。
              </p>


              {/*
              [登録用Webページ](https://app.curon.co/add-clinic/clinics/Q2FP_9ypLyei4ONGkwoT9tcsq471oDTHbaT_hpxl9Zc)
              */}

                <a 
                  href="https://app.curon.co/add-clinic/clinics/Q2FP_9ypLyei4ONGkwoT9tcsq471oDTHbaT_hpxl9Zc" 
                  style={
                    {
                      display: "block",
                      color: '#fff',
                      backgroundColor: '#eb6100',
                      border: '0',
                      borderRadius: '10px',
                      padding: '8px 8px',
                      marginTop: '30px',
                      marginBottom: '40px',
                      textAlign: "center",
                    }
                  }
                >
                  登録用Webページ
                </a>


            <h2 id="その他の注意事項">その他の注意事項</h2>

              <ListItem 
                items={[
                  "サービスの利用に先立って、クロンへの登録が必要です。",
                  "クロンの利用には、クレジットカードの登録が必須になります。",
                  "サービスの利用には、別途クロン側にてアプリ使用料(300円程度)が発生します。"
                  ]}
                >
              </ListItem>

  </Layout>
  )
}

export default OnlinePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
    // allFile {
    //   edges {
    //     node {
    //       name
    //       childImageSharp {
    //         gatsbyImageData(layout: CONSTRAINED)
    //       }
    //     }
    //   }
    // }
