import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';

const StyledImage = styled.div`
  /* ~~~ container styles ~~~ */
  margin: auto;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px;
`

export default StyledImage
